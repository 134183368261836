body {

    padding-top: 85px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 74px;
        left: 50%;
        width: 796px;
        height: 567px;
        margin: 0 0 0 -154px;
        background: url( ../images/working.png ) no-repeat;
        background-size: contain;
        z-index: 1;
    }

    > header {

        display: block;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        height: 65px;
        padding: 0 20px;
        background-color: rgba( 22, 149, 164, 0.9 );
        z-index: 9900;
        transition: all 600ms;

    	> div {

            max-width: 1178px;
            margin: 0 auto;
            display: block;
            @extend .clear;

    	}

        h1 {
            float: left;
            margin: 10px 0;
        }

        .menu-toggle {
            position: relative;
            float: right;
            margin-top: 9px;
            z-index: 110;
        }

        &.start {
            transition: none;
            background: transparent;
        }
        &.out {
            transform: translateY( -110% );
        }
        &.initial {

            height: 85px;
            background-color: transparent;
           
            h1 {
                float: left;
                margin: 20px 0;
            }
            
            .menu-toggle {
                margin-top: 19px;
            }
        
        }

    }

}

@media( max-width: 980px ){

    body {

        background-size: 1127px;
        background-position: calc( 50% + 20px ) -30px;

        &:before {
            top: 72px;
            left: 50%;
            width: 430px;
            height: 309px;
            margin: 0 0 0 -96px;
        }

        > header {

            height: 60px;

            h1 {

                margin-top: 14px;

                img {
                    width: 60px;
                }

            }
           
        }

    }

}

@media( max-width: 768px ){


    body {

        background-image: url( ../images/graph-topo-mobile.svg );
        background-size: 100% auto;
        background-position: 0 -70px;

        > header {

            .menu-toggle {
                margin-top: 7px;
            }

            &.initial {

                height: 60px;
                
                h1 {
                    margin: 12px 0;
                }

                .menu-toggle {
                    margin-top: 6px;
                }
            
            }
        
        }

        &:before {
            margin: 0;
            transform: translateX(-50%);
        }

    }

}

@media( max-width: 500px ){
    body > header {
        > div .menu-toggle {
            margin-right: -6px;
        }
    }
}

@media( max-width: 550px ){

    body {
        background-position: 0 0;
    }

}

@media( max-width: 600px ){

    body {

        &:before {
            top: 64px;
        }
    
    }

}
