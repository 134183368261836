section.credito {

    display: block;

    h2 {
        margin-bottom: 76px;
        color: #617385;
    }

    ul {
        display: flex;
        justify-content: space-between;
    }

    li {

        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 376px;
        height: 123px;
        padding: 43px 67px 25px;
        background: #FFFFFF;
        text-align: center;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -36px;
            left: 50%;
            width: 69px;
            height: 72px;
            transform: translateX(-50%);
            background: no-repeat center center;
            background-size: contain;
        }
        
        &.jet:before {
            width: 42px;
            background-image: url( ../images/foguete.svg );
        }
        
        &.cash:before {
            background-image: url( ../images/dolar.svg );
        }
        
        &.pc:before {
            background-image: url( ../images/computador.svg );
        }
    
    }

    h3 {
        font-weight: 900;
        font-size: 24px;
        color: #2C5871;
    }

    em {
        display: table;
        max-width: 376px;
        margin: 32px 0 0 auto;
        font-style: italic;
        font-size: 16px;
        line-height: 100%;
        text-align: right;
        color: #617385;
    }

}

@media( max-width: 1224px ){

    section.credito {

        ul {

            flex-wrap: wrap;
            justify-content: center;

            li {
                margin: 24px; 
            }

        }

        em {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    
    }

}

@media( max-width: 600px ){

    section.credito {

        li {
            height: auto;
        }
        h3 {
            font-size: 16px;
        }
        em {
            margin-top: 8px;
        }
    
    }

}

@media( max-width: 480px ){

    #credito {

        h2 {
            max-width: 230px;
            margin: 0 auto 36px;
        }

        ul {

            li {

                width: 100%;
                padding: 20px;
                margin: 36px 0 0 0;
                box-shadow: none;

                &:first-child {
                    margin-top: 0;
                }

                &:before {
                    top: -16px;
                    height: 32px;
                    width: 31px;
                }

                &.jet:before {
                    width: 19px;
                }
            
            }

        }

        em {
            margin-top: 20px;
            max-width: 244px;
            font-size: 12px;
        }

    }

}