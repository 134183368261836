section.conheca-a-lei {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 0 36px;
    margin: 0 (-$hSpace);
    background: #1695A4;
    text-align: center;
    color: #fff;

    strong {
        display: block;
        max-width: 445px;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.02em;
        font-weight: normal;
    }

    a {
        margin-top: 20px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-decoration-line: underline;
        color: #CFE2FC;
    }

}

@media( max-width: 600px ){

    section.conheca-a-lei {
        strong {
            max-width: 320px;
            font-size: 20px;
            line-height: 24px;
        }
    }

}