#menu {

    position: absolute;
    top: 0;
    left: 50%;
    width: 628px;
    padding: 49px 86px;
    background: #F5F7FA;
    z-index: 100;
    transform: translate( -25px, -100%);
    transition: all 600ms;

    a {

        display: block;
        width: 100%;
        max-width: 458px;
        padding: 12px 0;
        font-weight: 300;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        text-decoration: none;
        outline: none;

        
        &.active {
            //font-weight: normal;
        }
    
    }

}

body.menu-opened #menu {
    transform: translate( -25px, 0 );
    box-shadow: 0px 32px 30px rgba( 20, 46, 82, 0.2 );
}

@media( min-width: 768px ){
    #menu a:hover {
        text-decoration: underline;
    }
}

@media( max-width: 1206px ){

    #menu {

        left: initial;
        right: -20px;
        max-width: 520px;
        padding: 45px;
        transform: translate( 0, -100% );

    }

    body.menu-opened #menu {
        transform: translate( 0, 0 );        
    }

}

@media( max-width: 700px ){

    #menu {

        width: 400px;

        a {
            font-size: 20px;
            line-height: 24px;
        }

    }

}

@media( max-width: 600px ){
    #menu {
        padding: 45px 25px;
    }
}

@media( max-width: 500px ){

    #menu {

        left: -20px;
        width: auto;
        padding-top: 60px;
        padding-bottom: 20px;

        a {
            font-size: 20px;
            line-height: 20px;
            padding: 8px 0;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 12px;
            left: 20px;
            width: 60px;
            height: 32px;
            background: url( ../images/sebrae-menu.svg ) no-repeat;
            background-size: contain;
        }
    }

}