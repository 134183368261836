body {

	padding-bottom: 97px;
	
	> footer {

		display: flex;
		position: absolute;
		align-items: center;
		justify-content: center;
	  	height: 97px;
		left: 0;
		right: 0;
		bottom: 0;
		background: #0E303F;

		img {
			display: block;
			width: 83px;
		}
		
	}

}

@media( max-width: 600px ){

	body {

		padding-bottom: 72px;

		> footer {

			height: 72px;

			img {
				width: 60px;
			}
		
		}

	}

}