section.prazos {

    padding-bottom: 34px;

    h3 {
        font-size: 28px;
        line-height: 100%;
        color: #1695A4;
    }

    em {
        display: block;
        margin-top: 32px;
        padding-bottom: 3px;
        font-size: 24px;
        line-height: 100%;
        text-align: center;
        color: #394D60;
    }

    ul {
        width: 436px;
        padding-bottom: 75px;
        margin: 11px auto 0;
    }

}

@media( max-width: 480px ){

    #prazos {

        padding-bottom: 26px;
        
        ul {
            width: 100%;
            padding-bottom: 50px;
        }

        em {
            font-size: 20px;
            line-height: 21px;
        }
    
    }

}