// Structure
@import "normalize";
@import "vars";
@import "base";
@import "menu-toggle";
@import "menu";
@import "header";
@import "footer";
@import "sanfona";
@import "slick";

// Sections
@import "sections/inicio";
@import "sections/o-que-e";
@import "sections/enquadramento";
@import "sections/credito";
@import "sections/prazos";
@import "sections/juros";
@import "sections/limites-de-credito";
@import "sections/garantias";
@import "sections/onde-contratar";
@import "sections/sebrae";
@import "sections/conheca-a-lei";

// Breakpoints
@media( min-width: 768px ){
    body .just-mobile {
        display: none !important;
    }
}
@media( max-width: 767px ){
    body .hide-mobile {
        display: none !important;
    }
}