section.garantias {

    padding: 38px 0 68px;
    background: #FFDC79;

    h2 {
        display: block;
        margin-bottom: 58px;
        text-align: center;
        color: #243342;
    }

    ul {

        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 25px;

        li {

            position: relative;
            width: 285px;
            padding: 0 10px;

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                width: 1px;
                height: 112px;
                background: #617385;
                transform: translateY(-50%);
            }

            &:last-child:after {
                display: none;
            }
        
        }
    
    }

    em {

        display: block;
        position: absolute;
        top: -52px;
        left: 0;
        right: 0;
        padding: 0 30px;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        color: #617385;

    }

    p {
        display: block;
        margin: 0;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        color: #243342;
    }

    em + p strong {
        color: #204e65;
    }

}

@media( max-width: 1224px ){

    section.garantias {

        ul {

            flex-wrap: wrap;
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;

            li {

                width: 50%;

                &:nth-child(2){
                    &:after {
                        display: none;
                    }
                }
                &:nth-child(3),
                &:nth-child(4){
                    margin-top: 50px;
                }
            
            }
        
        }

    }

}

@media( max-width: 600px ){

    section.garantias {

        padding: 24px 0 0;

        h2 {
            margin-bottom: 24px;
        }

        ul {

            display: block;
            width: 100%;
            padding: 0 13px;

            li {

                width: 100%;
                padding: 0 0 33px;
                margin: 0 0 32px;

                &:after {
                    top: initial;
                    left: 28px;
                    right: 28px;
                    bottom: 0;
                    width: auto;
                    height: 1px;
                };
                &:nth-child(2):after {
                    display: block;
                }
                &:nth-child(3),
                &:nth-child(4) {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            
            }
        
        }

        em {
            position: relative;
            top: initial;
            margin-bottom: 22px;
        }

    }

}