*, *:after, *:before {
    box-sizing: border-box;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
}
html {
    position: relative;
    width: 100%;
    min-height: 100%;
    font-size: 62.5%;
    overflow-y: auto;
    overflow-x: hidden;
}
body {

    width: 100%;
    height: 100%;
    padding: 0 20px;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-size: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f3f6f9;
    background-image: url( ../images/graph-topo-full.svg );
    background-repeat: no-repeat;
    background-position: calc( 50% + 150px ) top;

    &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 1400px;
        height: 1508px;
        width: 633px;
        background: url( ../images/blue-vector.svg ) no-repeat;
        background-size: contain;
        z-index: 1;
    }

}

body, html {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

main {

    position: relative;
    z-index: 2;

    > section {
        margin-top: 52px;
    }

}

h2, h3 {
    display: block;
    margin: 0;
}

h2 {
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 32px;
    line-height: 100%;
    color: #1695A4;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

button {
    background: transparent;
    border: none;
    padding: 0;
}

em {
    font-style: normal;
}

.hide-text {
    overflow: hidden;
    text-indent: -99999px;
}

.accessibilidade {

    display: block;
    
    a {
        position: absolute;
        left: -99999px;
        top: 0;
    }

}

.wcf {

    display: block;
    max-width: 976px;
    margin-left: auto;
    margin-right: auto;

    &.size-2 {
        max-width: 1176px;
    }

    &.size-3 {
        max-width: 576px;
    }

}

.esp-top {
    margin-top: 52px;
}

.square-box {

    position: relative;
    margin-top: 132px;
    padding-top: 116px;
    text-align: center;
    background: #FFFFFF;

    h2 {
        font-size: 32px;
    }

    &:before {

        content: '';
        display: block;
        position: absolute;
        top: -80px;
        left: 50%;
        width: 176px;
        height: 176px;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateX(-50%);
    
    }

    &.calendario:before {
        background-image: url( ../images/calendario.svg );
    }

    &.graficos:before {
        background-image: url( ../images/graficos.svg );
    }

}

.slick-arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 20px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
    transform: all 300ms;
    cursor: pointer;
    overflow: hidden;
    @extend .hide-text;
}

.slick-prev {

    transform: translateX(-45px);
    background-image: url( ../images/arrow-left.svg );

    &.slick-disabled {
        background-image: url( ../images/arrow-left-disabled.svg ) ;
    }

}

.slick-next {

    transform: translateX(22px);
    background-image: url( ../images/arrow-right.svg );
    
    &.slick-disabled {
        background-image: url( ../images/arrow-right-disabled.svg ) ;
    }

}

@media( max-width: 970px ){
    body:after {
        width: 420px;
        height: 1000px;
    }
}

@media( max-width: 768px ){
    main > section {
        margin-top: 38px;
    }
}

@media( max-width: 600px ){

    body:after {
        display: none;
    }

    .square-box {

        padding-top: 92px;

        h2 {
            font-size: 32px;
            margin-bottom: 26px
        }

        em {
            font-size: 20px;
            padding-left: 20px;
            padding-right: 20px;
        }

        &:before {
            width: 145px;
            height: 145px;
            background-size: contain;
        }
    
    }

}

@media( max-width: 480px ){

    body {
        padding: 0 20px;
    }

    h2 {
        font-size: 24px;
    }

    .square-box {

        padding-top: 52px;
        margin-top: 87px;

        h2 {
            margin-bottom: 8px;
            font-size: 24px;
        }

        h3 {
            font-size: 20px;
        }

        em {
            margin-top: 22px;
            line-height: 20px;
        }

        &:before {
            top: -40px;
            width: 80px;
            height: 80px;
        }
    
    }

}

.clear:before,
.clear:after {
    content: ".";
    display: block;
    height: 0;
    width: 0;
    overflow: hidden; 
}
.clear:after{ 
    clear: both;  
}
.clear { 
    zoom: 1; 
}