.menu-toggle {

	display: flex;
	width: 40px;
	height: 40px;
	align-items: center;
	justify-content: center;
	padding: 0;
	cursor: pointer;
	z-index: 10;

	.icon {

		display: block;
		width: 32px;
		height: 12px;
		position: relative;

		span {

			display: block;
			position: absolute;
			height: 3px;
			width: 100%;
			opacity: 1;
			right: 0;
			border-radius: 1.1px;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;
			background: #ffffff;

			&:nth-child(1) {
				width: 26px;
				top: 4px;
			}
			&:nth-child(2) {
				top: 12px;
			}
		
		}

	}
		

	&.opened .icon {

		width: 22px;
		height: 22px;

		span {

			&:nth-child(1),
			&:nth-child(2) {
				top: 13px;
				width: 25px;
				height: 3px;
				background: #617385;;
			}

			&:nth-child(1) {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			&:nth-child(2) {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}

		}

	}

}
