section.limites-de-credito {

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: #1695A4;
    padding: 35px 20px 65px;
    color: #fff;

    h2 {
        flex-basis: 100%;
        margin-bottom: 60px;
        text-align: center;
        color: #FFFFFF;
    }

    h3 {
    
        display: block;
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        font-weight: normal;
        
        strong {
            font-weight: 900;
        }
    
    }

    > div {

        position: relative;

        &:nth-of-type(1){

            width: 246px;
            margin-right: 105px;
            margin-left: 65px;

            p:after {
                content: '';
                display: block;
                position: absolute;
                right: -66px;
                top: 0;
                bottom: 0;
                width: 1px;
                background: #FFFFFF;
            }
        
        }
    
        &:nth-of-type(2){

            width: 390px;

            h3 {
                padding-right: 72px;
            }

            div {

                display: flex;

                p:first-child {
                    width: 125px;
                }
                p:last-child {
                    width: 130px;
                }

                > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 92px;
                    padding-right: 6px;
                    text-align: center;
                    font-weight: 900;
                    font-size: 20px;
                }
            
            }
        
        }
    
    }

    p {

        display: block;
        position: relative;
        width: 173px;
        margin: 0 auto;

        span, strong, em {
            display: block;
        }

        span {
            font-weight: 300;
            font-size: 24px;
            line-height: 100%;
        }

        strong {
            font-weight: 900;
            font-size: 60px;
            line-height: 100%;
            color: #F2C94C;
        }

        em {
            font-size: 24px;
            line-height: 100%;
        }
    
    }

}

@media( max-width: 980px ){

    section.limites-de-credito {

        display: block;

        > div {

            &:nth-of-type(1){
                
                margin: 0 auto;

                p {

                    padding-bottom: 28px;

                    &:after {
                        top: initial;
                        left: -66px;
                        height: 1px;
                        width: auto;
                        //display: none;
                    }
                }

            }

            &:nth-of-type(2){
                
                margin: 40px auto 0;

                h3 {
                    padding-right: 0;
                }

            }

        }

    }

}

@media( max-width: 480px ){

    section.limites-de-credito {

        padding-top: 25px;

        h2 {
            margin-bottom: 22px;
        }

        h3 {
            max-width: 185px;
            margin: 0 auto;
            font-size: 16px;
        }

        p {

            span {
                font-size: 20px;
            }
            strong {
                font-size: 48px;
            }
            em {
                margin-top: 10px;
                font-size: 20px;
            }

        }

        > div {

            &:nth-of-type(1) p {
                width: 140px;
                padding-bottom: 28px;
                margin-top: 16px;
            }
        
            &:nth-of-type(2) {

                width: 100%;

                h3 {
                    max-width: 220px;
                }

                div {

                    width: 270px;
                    margin: 22px auto 0;

                    p {

                        &:first-child {
                            margin: 0;
                            width: 95px;
                        }
                        &:last-child {
                            margin: 0;
                            width: 100px;
                        }

                        em {
                            font-size: 18px;
                            line-height: 18px;
                        }

                    }

                    > span {
                        width: 72px;
                    }

                }

            }
        
        }

    }

}

@media( max-width: 360px ){

    section.limites-de-credito {
        
        > div:nth-of-type(2) div {

            width: 250px;
            
            > span {
                width: 50px;
            }
        
        }
    
    }

}
