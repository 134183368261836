section.inicio {

    @extend .wcf;
    margin-top: 210px;

    em {
        display: block;
        font-weight: 900;
        font-size: 80px;
        line-height: 100%;
        color: #0E303F;
    }

    p {
        margin: 4px 0 0;
        max-width: 420px;
        font-size: 32px;
        line-height: 38px;
        color: #2C5871;
    }

}

@media( max-width: 980px ){
    
    section.inicio {
        
        max-width: 768px;
        margin-top: 110px;
        padding-left: 20px;
   
        em {
            font-size: 42px;
        }

        p {
            max-width: 294px;
            font-size: 20px;
            line-height: 24px;
        }

    }

}

@media( max-width: 768px ){
    
    section.inicio {

        max-width: 400px;
        padding: 0 20px;
        margin: 300px auto 0;

        em {
            font-size: 46px;
        }
        p {
            max-width: 100%;
            font-size: 26px;
            line-height: 30px;
        }

    }

}

@media( max-width: 600px ){

    section.inicio {

        margin-top: 292px;
        
        em {
            font-size: 32px;
        }
        
        p {
            max-width: 294px;
            margin-top: 11px;
            font-size: 20px;
            line-height: 24px;
        }
    
    }

}

