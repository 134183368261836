section.o-que-e {

    position: relative;
    display: block;
    margin-top: 0;
    padding-top: 196px;

    > div {
        position: relative;
        background: #fff;
        padding: 58px 20px;
        text-align: center;
        z-index: 2;
    }

    h2 {
        margin: 0;
    }

    p {
        max-width: 776px;
        margin: 30px auto 0;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #394D60;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -17px;
        left: (-$hSpace);
        width: 267px;
        height: 542px;
        background: url( ../images/yellow-vector.svg ) no-repeat;
        background-size: contain;
        z-index: 1;
    }

}


@media( max-width: 980px ){

    section.o-que-e {    

        padding-top: 98px;
        
        &:before {
            display: none;
        }
    
    }

}


@media( max-width: 600px ){

    section.o-que-e {

        padding-top: 48px;

        > div {
            padding-top: 28px;
            padding-bottom: 22px;
        }
        
        p {
            margin-top: 6px;
            font-size: 20px;
            line-height: 24px;
        }
    
    }

}

@media( max-width: 480px ){

    section.o-que-e {
        margin: 0 -20px;
    }

}
