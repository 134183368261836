section.sebrae {

    padding: 28px 0 38px 0;
    margin-bottom: 42px;
    text-align: center;
    background: #fff;
    @extend .clear;

    > strong {
        display: table;
        width: 110px;
        height: 60px;
        margin: 0 auto;
        background: url( '../images/sebrae.svg' ) no-repeat;
        @extend .hide-text;
    }

    p {
        display: block;
        max-width: 612px;
        padding: 0 16px;
        margin: 29px auto 0;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #394D60;
    }

}

@media( max-width: 600px ){

    section.sebrae {
        p {
            font-size: 20px;
            line-height: 24px;
        }
        strong {
            background-size: contain;
            width: 92px;
            height: 50px;
        }
    }

}


@media( max-width: 480px ){

    section.sebrae {

        padding-bottom: 22px;
        margin-left: -20px;
        margin-right: -20px;
        
        p {
            margin-top: 21px;
        }
    
    }

}
