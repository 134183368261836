section.enquadramento {

    display: block;
    max-width: 584px;
    margin-left: auto;
    margin-right: auto;

    h2 {
        color: #617385;
    }

    h3 {
        font-size: 32px;
        line-height: 100%;
        color: #243342;
    }

    em {
        display: block;
        margin-top: 5px;
        font-size: 20px;
        line-height: 100%;
        color: #243342;
    }

    li {

        position: relative;
        padding: 34px 33px 34px 132px;
        margin-top: 24px;
        background: linear-gradient( 270deg, #FFFFFF 0%, #F5F7FA 100% );

        &:before {

            content: '';
            display: block;
            position: absolute;
            left: 16px;
            top: 50%;
            width: 92px;
            height: 92px;
            background: no-repeat;
            background-size: contain;
            transform: translateY(-50%);
        
        }

        &.microempresas:before {
            background-image: url( ../images/venda.svg );
        }

        &.pequenas-empresas:before {
            background-image: url( ../images/predio.svg );
        }

    }

}

@media( max-width: 600px ){

    section.enquadramento {
        h3 {
            font-size: 24px;
        }
        em {
            font-size: 12px;
        }
    }

}

@media( max-width: 480px ){

    section.enquadramento {

        li {

            padding: 24px 24px 24px 92px;
            margin-top: 16px;

            &:before {
                width: 68px;
                height: 68px;
                background-size: contain;
            }

        }
    
    }

}
