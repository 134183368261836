section.juros {

    padding-bottom: 61px;

    h2 {
        margin-bottom: 46px;
    }

    em {

        font-weight: 900;
        font-size: 32px;
        line-height: 100%;
        text-align: center;
        color: #394D60;

        span {
            font-weight: normal;
        }
    
    }

}

@media( max-width: 600px ){

    #juros {
        padding-bottom: 32px;
    }

}

@media( max-width: 480px ){

    #juros {
        h2 {
            margin-bottom: 23px;
        }
        em {
            font-size: 20px;
        }
    }

}