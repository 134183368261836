section.onde-contratar {

    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 104px 42px 50px;
    margin-top: 243px;
    background: #fff;

    h2 {
        flex-basis: 100%;
        margin-bottom: 56px;
    }

    p {

        width: 50%;
        padding-bottom: 10px;
        margin: 0;
        text-align: center;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.02em;
        color: #394D60;

        &:nth-child(2) {
            margin-right: -1px;
            border-right: 1px solid #1695A4;
            padding-right: 36px;
        }

        &:nth-child(3) {
            border-left: 1px solid #1695A4;
            padding-left: 36px;
        }
    
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -70px;
        top: -193px;
        width: 401px;
        height: 370px;
        background: url( '../images/construcao.png' ) no-repeat;
        background-size: contain;
    }

}

@media( max-width: 1224px ){

    section.onde-contratar {

        padding-top: 120px;

        &:before {
            left: 50%;
            width: 330px;
            transform: translateX(-50%);
            height: 306px;
        }

    }

}

@media( max-width: 600px ){

    section.onde-contratar {    
        
        display: block;
        padding-bottom: 8px;

        h2 {
            margin-bottom: 24px;
        }

        p {

            position: relative;
            width: 100%;
            font-size: 20px;
            line-height: 24px;

            &:nth-child(2),
            &:nth-child(3) {
                border: 0;
                padding-left: 0;
                padding-right: 0;
            }
            &:nth-child(2){

                padding-bottom: 20px;
                
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 40px;
                    right: 40px;
                    height: 1px;
                    background: #1695A4;
                }
            }
            &:nth-child(3) {

                padding-top: 22px;
            
            }
        
        }

    }

}

@media( max-width: 480px ){

    section.onde-contratar {

        padding: 84px 20px 10px 20px;
        margin-top: 152px;
        margin-left: -20px;
        margin-right: -20px;
        
        &:before {
            top: -135px;
            width: 214px;
            height: 208px;
        }

    }

}
