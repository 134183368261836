.piki-sanfona {
    
    display: block;
    max-width: 976px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    position: relative;
    z-index: 30;

    &.sanfona {

        .sanfona-item {
            
            background: transparent;
            margin-bottom: 20px;
            box-shadow: 0px 4px 8px rgba( 20, 46, 82, 0.2 );

            .sanfona-header {
                
                display: block;
                margin: 0;
                background: #FFFFFF;
                border-radius: 4px;
                transition: border-radius 300ms, box-shadow 300ms;

                a {

                    display: block;
                    position: relative;
                    padding: 20px 64px 20px 30px;
                    font-size: 20px;
                    color: #0058a1;
                    letter-spacing: 0;
                    line-height: 24px;
                    font-weight: bold;
                    cursor: pointer;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        top: 50%;
                        width: 4px;
                        height: 15px;
                        margin-top: -5px;
                        background: #215899;
                        transition: all 400ms;
                    }
                    &:before {
                        right: 38px;
                        transform: rotate(135deg);
                    }
                    &:after {
                        right: 30px;
                        transform: rotate(45deg);
                    }
                
                }

                &.opened {

                    a {
                        &:before {
                            transform: rotate(45deg);
                        }
                        &:after {
                            transform: rotate(135deg);
                        }
                    }
               
                }
            
            }

            .sanfona-content {

                padding: 0 64px 5px 20px;
                border-radius: 0 0 4px 4px;
                font-size: 16px;
                color: #696969;
                letter-spacing: 0;
                line-height: 20px;
                background: #fff;

                p {

                    margin: 0;
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: -0.25px;
                    color: #737373;

                }

            }

            > .trigger {

                display: block;
                position: relative;
                height: 47px;
                padding-left: 66px;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: -0.5px;
                color: #2379ff;
                border: 0;
                border-top: 2px solid #f8f7f7;
                background: #fff;
                text-align: left;
                display: none;

                &:before,
                &:after {
                    content: '';
                    display: inline-block;
                    background: #2379ff;
                    position: absolute;
                    top: 50%;
                    transform: translateY( -50% );
                }
                &:before {
                    left: 20px;
                    width: 16px;
                    height: 2px;
                }
                &:after {
                    left: 27px;
                    width: 2px;
                    height: 16px;
                    transition: height 300ms;
                }
            
            }

            &.opened {

                .sanfona-content {
                    box-shadow: none;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                
                > .trigger {
                    border-top: transparent;
                    &:after {
                        height: 0;
                    }
                }
            
            }
        
        }
    
        &.theme-2 {

            .sanfona-header {
                
                border-radius: 0;

                a {

                    padding: 25px 64px 25px 20px;
                    text-decoration: none;
                    text-align: left;

                    div {
                        position: relative;
                    }

                    img {
                        width: 100px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY( -50% );
                    }

                    span {
                        float: left;
                        width: 100%;
                        padding-left: 140px;
                    }

                    strong {
                        display: block;
                        font-weight: 300;
                        font-size: 24px;
                        line-height: 100%;
                        color: #1695A4;
                    }

                    p {
                        margin: 2px 0 0;
                        font-size: 20px;
                        line-height: 1.2;
                        letter-spacing: -0.63px;
                        color: #333333;
                        font-weight: normal;
                    }

                
                    &:before,
                    &:after {
                        background: #394D60;
                        margin: 0;
                        transform: rotate(0) translateY( -50% );
                    }
                    &:before {
                        right: 25px;
                        width: 14px;
                        height: 2px;
                    }
                    &:after {
                        right: 31px;
                        width: 2px;
                        height: 14px;
                    }
                
                }

                
                &.opened {

                    border-radius: 0;

                    a {
                        &:before {
                            transform: rotate(0);
                        }
                        &:after {
                            height: 0;
                            transform: rotate(0);
                        }
                    }
               
                }
            
            }
            
            .sanfona-item .sanfona-content {

                p {

                    margin-bottom: 22px;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: -0.02em;
                    color: #394D60;

                    &:before {
                        content: '';
                        display: block;
                        margin: 0 0 18px 22px;
                        height: 1px;
                        background: #E0E6EB;
                    }

                    &:first-child:before {
                        display: none;
                    }

                }
                
            }

        }

    }

}

@media( max-width: 480px ){
    .piki-sanfona.sanfona.theme-2 .sanfona-header a {
        display: flex;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        height: 74px;
    }
} 